import React, { useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/react';
import { GalleryPageData, ImageWithSize } from '../types';
import { useWindowSize } from '../services/useWindowSize';
import { CustomPhoto } from '../components/Gallery/CustomPhoto';
import { Markdown } from '../components/Layout/Markdown';
import { Container } from '../components/Layout/Container';
import { transformCloudinaryImage } from '../services/cloudinary';
import { Title } from '../components/Layout/Title';
import { isMobileWidth, belowEqualMobile } from '../services/mediaquery';
import { ReadMore } from '../components/ReadMore';

const DescriptionWrapper = styled('div')`
    background-color: #fbf3ed;
    margin-bottom: 30px;
    padding: 30px 0;
    ${belowEqualMobile} {
        padding: 0;
    }
`;

// const Link = styled(Markdown.withComponent('a'))`
//     margin-top: 30px;
//     display: block;
//     text-decoration: underline;
// `;

function getColumns(width: number = 0) {
    if (width >= 1000) {
        return 3;
    }
    if (width >= 700) {
        return 2;
    }
    return 1;
}

function GalleryPage(props: PageProps<object, GalleryPageData<ImageWithSize>>) {
    const {
        pageContext: { title, description, images },
    } = props;

    const size = useWindowSize();

    const isMobile = size ? isMobileWidth(size.width) : false;

    const [currentImage, setCurrentImage] = useState(0);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    function openLightbox(event: any, props: { index: number }) {
        setCurrentImage(props.index);
        setIsLightboxOpen(true);
    }

    function closeViewer() {
        setCurrentImage(0);
        setIsLightboxOpen(false);
    }

    const galleryImages = useMemo(
        () =>
            size
                ? images.map((image) => ({
                      src: image.original.url,
                      height: image.original.height,
                      width: image.original.width,
                  }))
                : [],
        [size],
    );

    const lightboxImages = useMemo(
        () =>
            size
                ? images.map((image) => {
                      const transformedImage = transformCloudinaryImage(
                          image.original,
                          {
                              maxHeight: size.height,
                              maxWidth: size.width,
                          },
                      );
                      return {
                          source: transformedImage.url,
                      };
                  })
                : [],
        [size],
    );

    return (
        <>
            {title && (
                <Container>
                    <Title>{title}</Title>
                </Container>
            )}
            {description && (
                <DescriptionWrapper>
                    <Container>
                        {description &&
                            (isMobile ? (
                                <ClassNames>
                                    {({ css, cx }) => (
                                        <ReadMore
                                            maxHeight={150}
                                            readMoreLabel="(קרא עוד)"
                                            readLessLabel="(סגור)"
                                            contentClassName={css`
                                                &:after {
                                                    background: linear-gradient(
                                                        180deg,
                                                        rgba(255, 255, 255, 0)
                                                            50%,
                                                        #fbf3ed 100%
                                                    );
                                                }
                                            `}
                                        >
                                            <Markdown>{description}</Markdown>
                                        </ReadMore>
                                    )}
                                </ClassNames>
                            ) : (
                                <Markdown>{description}</Markdown>
                            ))}
                        {/* {link && link.text && (
                            <Link href={link.href}>{link.text}</Link>
                        )} */}
                    </Container>
                </DescriptionWrapper>
            )}

            <Gallery
                photos={galleryImages}
                direction="column"
                columns={getColumns(size?.width)}
                onClick={openLightbox}
                targetRowHeight={size ? size.height / 2 : 0}
                renderImage={CustomPhoto}
            />
            <ModalGateway>
                {isLightboxOpen ? (
                    <Modal onClose={closeViewer}>
                        <Carousel
                            currentIndex={currentImage}
                            views={lightboxImages}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </>
    );
}

export default GalleryPage;
