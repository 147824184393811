import React, { useMemo } from 'react';
import { PhotoProps } from 'react-photo-gallery';
import { transformCloudinaryImage } from '../../services/cloudinary';
import { ImageWithSize } from '../../types';

type Direction = 'column' | 'row';

interface CustomPhotoProps<D extends Direction> {
    index: number;
    photo: PhotoProps;
    direction?: D;
    onClick:
        | ((
              event: React.MouseEvent<HTMLImageElement, MouseEvent>,
              info: { photo: PhotoProps; index: number },
          ) => void)
        | null;
    margin?: string;
    top?: D extends 'column' ? number : number | undefined;
    left?: D extends 'column' ? number : number | undefined;
    key?: string;
}

function CustomPhoto<D extends Direction>(props: CustomPhotoProps<D>) {
    const { margin, direction, left, top, onClick, photo, index, key } = props;

    const imgStyle: React.CSSProperties = { margin, display: 'block' };

    if (direction === 'column') {
        imgStyle.position = 'absolute';
        imgStyle.left = left;
        imgStyle.top = top;
    }

    function handleClick(
        event: React.MouseEvent<HTMLImageElement, MouseEvent>,
    ) {
        onClick && onClick(event, { photo, index });
    }

    const { src, srcSet, sizes, width, height, alt } = photo;

    const imageWithSize: ImageWithSize = { url: src, height, width };
    const transformedImage = transformCloudinaryImage(imageWithSize, {
        fixedHeight: height,
        fixedWidth: width,
    });

    return (
        <img
            key={key}
            style={onClick ? { ...imgStyle, cursor: 'pointer' } : imgStyle}
            onClick={handleClick}
            src={transformedImage.url}
            srcSet={Array.isArray(srcSet) ? srcSet.join(',') : srcSet}
            sizes={Array.isArray(sizes) ? sizes.join(',') : sizes}
            width={width}
            height={height}
            alt={alt}
        />
    );
}

export { CustomPhoto };
